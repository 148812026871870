import PropTypes from 'prop-types'

import ModalTrigger from 'common/modals/ModalTrigger'
import { gettext, ngettext } from 'common/language'
import formatPrice from 'common/utils/formatPrice'
import ServiceReviews from './ServiceReviews'
import classNames from './styles.module.scss'


ServiceReviewBadgeView.propTypes = {
  rating: PropTypes.number.isRequired,
  percentage: PropTypes.number.isRequired,
  reviewCount: PropTypes.number.isRequired,
}

export default function ServiceReviewBadgeView({ rating, percentage, reviewCount }) {
  return (
    <ModalTrigger
      component={ServiceReviews}
      rating={rating}
      reviewCount={reviewCount}
      maskClosable={false}
      title={gettext('Boa Lingua Reviews')}
      modalClassName={classNames.serviceModal}
    >
      <button className={classNames.serviceBadge}>
        <div className={classNames.percentage}>
          <span>{percentage}</span>
          <span>%</span>
        </div>
        <div className={classNames.badgeCol}>
          <p>
            {gettext('All Customers would recommend Boa Lingua')}
          </p>
          <p>
            {formatPrice(reviewCount)} {ngettext('Review', 'Reviews', reviewCount)}
          </p>
        </div>
      </button>
    </ModalTrigger>
  )
}
